import { render, staticRenderFns } from "./Calendar.vue?vue&type=template&id=48285a3b&"
import script from "./Calendar.vue?vue&type=script&lang=ts&"
export * from "./Calendar.vue?vue&type=script&lang=ts&"
import style0 from "./Calendar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonCircle: require('/tmp/build_7b3dd481/src/components/button/Circle.vue').default,ShowsListItem: require('/tmp/build_7b3dd481/src/components/shows/ListItem.vue').default,Modal: require('/tmp/build_7b3dd481/src/components/modal/Modal.vue').default})
