import { render, staticRenderFns } from "./UpcomingShows.vue?vue&type=template&id=2ed0af89&"
import script from "./UpcomingShows.vue?vue&type=script&lang=ts&"
export * from "./UpcomingShows.vue?vue&type=script&lang=ts&"
import style0 from "./UpcomingShows.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowsCalendar: require('/tmp/build_7b3dd481/src/components/shows/Calendar.vue').default,Button: require('/tmp/build_7b3dd481/src/components/button/Button.vue').default,ShowsListItemSkeleton: require('/tmp/build_7b3dd481/src/components/shows/ListItemSkeleton.vue').default,ShowsListItem: require('/tmp/build_7b3dd481/src/components/shows/ListItem.vue').default,Modal: require('/tmp/build_7b3dd481/src/components/modal/Modal.vue').default,BlockWrapper: require('/tmp/build_7b3dd481/src/components/block/BlockWrapper.vue').default})
