import { render, staticRenderFns } from "./SiteHeader.vue?vue&type=template&id=17836ada&"
import script from "./SiteHeader.vue?vue&type=script&lang=ts&"
export * from "./SiteHeader.vue?vue&type=script&lang=ts&"
import style0 from "./SiteHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logotype: require('/tmp/build_7b3dd481/src/components/misc/Logotype.vue').default,NavigationLink: require('/tmp/build_7b3dd481/src/components/navigation/NavigationLink.vue').default,ButtonCircle: require('/tmp/build_7b3dd481/src/components/button/Circle.vue').default,SiteHeaderUserPagesIconButton: require('/tmp/build_7b3dd481/src/components/navigation/SiteHeader/UserPagesIconButton.vue').default,SiteHeaderSecondaryNavToggle: require('/tmp/build_7b3dd481/src/components/navigation/SiteHeader/SecondaryNavToggle.vue').default,MobileNavigationToggle: require('/tmp/build_7b3dd481/src/components/navigation/MobileNavigation/MobileNavigationToggle.vue').default,SiteHeaderSecondaryNav: require('/tmp/build_7b3dd481/src/components/navigation/SiteHeader/SecondaryNav.vue').default})
